import KeyboardHandler from './keyboardhandler';

class ShortcutHandler extends KeyboardHandler {

    validElementFocus = [ 'BODY', 'DIV' ];

    constructor(data) {
        super(data);
    }

    init() {
        this.handleKeyDownShortcut = this.handleKeyDownShortcut.bind(this);
        this.handleKeyUpShortcut = this.handleKeyUpShortcut.bind(this);

        window.addEventListener('keydown', this.handleKeyDownShortcut);
        window.addEventListener('keyup', this.handleKeyUpShortcut);
    }

    clear() {
        window.removeEventListener('keydown', this.handleKeyDownShortcut);
        window.removeEventListener('keyup', this.handleKeyUpShortcut);
    }

    handleKeyDownShortcut(e) {
        if ( this.hasValidFocus() ){
            return;
        }
        this.handleKeyDown(e);
    }

    handleKeyUpShortcut(e) {
        if ( this.hasValidFocus() ){
            return;
        }
        this.handleKeyUp(e);
    }

    hasValidFocus() {
        return false;//this.validElementFocus.indexOf(document.activeElement.tagName) == -1;
    }
}

export default ShortcutHandler;