import {action, observable} from "mobx";
import Module from "./module";

class Period {

    id;
    @observable modules = [];
    track;

    @action
    addModule(moduleData, layoutModuleId){
        const newModule = new Module(moduleData.id, moduleData.title);
        this.modules.push(newModule);
        newModule.period = this;
        newModule.layoutModuleId = layoutModuleId || moduleData.layoutModuleId;
        return newModule;
    }

    @action
    deleteModule({id}){
        const index = this.modules.findIndex(module => module.id === id);
        if(index == -1){
            return;
        }
        this.modules.splice(index, 1);
    }

}

export default Period;