import React from 'react';
import './style.scss';
import Track from "../track/track";
import {observer} from "mobx-react";

@observer
class Layout extends React.Component {

    static defaultProps = {
        tracks: []
    };

    onDrop = (trackId, periodId, droppedModule) => {
        this.props.onDrop(this.props.id, trackId, periodId, droppedModule);
    };

    renderTrack = (track, i) => {
        return (
            <Track
                key={i}
                editMode={this.props.editMode}
                onPeriodClick={this.props.onPeriodClick}
                onModuleDelete={this.props.onModuleDelete}
                onOpenModule={this.props.onOpenModule}
                id={track.id}
                periods={track.periods}
                onDrop={this.onDrop}
            />
        );
    };
    renderPeriods = ({year, period}, i) => {
        return (
            <div className="periodIndicator" key={i}>
                {year}.{period}
            </div>
        )
    }

    render() {
        const {title, tracks, periods} = this.props;

        return (
            <div className="layout">
                <div className="title">{title}</div>
                <div className="tracks">
                    <div>
                        {periods.map(this.renderPeriods)}
                    </div>
                    {tracks.map(this.renderTrack)}}
                </div>

            </div>
        );
    }

}

export default Layout;
