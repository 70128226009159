import React from 'react';
import './style.scss';
import {observer} from "mobx-react";
import classNames from "classnames";

@observer
class Module extends React.Component {

    static defaultProps = {
        editMode : false,
        onDelete : () => {},
        onOpenModule : () => {},
    };

    onDelete = event => {
        event.stopPropagation();
        this.props.onDelete();
    };

    render() {
        const style = {
            left: `${this.props.x}px`,
            top: `${this.props.y}px`,
        };

        const className = classNames({
            module: true,
            ["module--editMode"]: this.props.editMode
        });

        return (
            <div
                className={className}
                style={style}
                onMouseDown={this.props.onMouseDown}
                onDoubleClick={this.props.onOpenModule}
            >
                <div className="title">{this.props.title}</div>
                <button
                    className="close"
                    type="button"
                    onMouseDown={this.onDelete}
                >
                    x
                </button>
            </div>
        );
    }

}

export default Module;
