import React from 'react';
import './style.scss';
import {createArray} from "../../utils/array.utils";
import Period from "../period/period";
import DropTarget from "../../utils/draganddrop/droptarget";
import {observer} from "mobx-react";

@observer
class Track extends React.Component {

    static defaultProps = {
        periods: []
    };

    onDrop = (periodId, {dragData}) => {
        this.props.onDrop(this.props.id, periodId, dragData);
    };

    renderPeriod = (period, i) => {
        const {modules} = period;
        return (
            <Period
                id={period.id}
                key={i}
                editMode={this.props.editMode}
                onClick={() => this.props.onPeriodClick(period)}
                onModuleDelete={this.props.onModuleDelete}
                onOpenModule={this.props.onOpenModule}
                modules={modules}
                onDrop={this.onDrop}
            />
        );
    };

    render() {
        return (
            <div className="track">
                {this.props.periods.map(this.renderPeriod)}
            </div>
        );
    }

}

export default Track;
