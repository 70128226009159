/**
 * Store for storing UI specific state
 */
import {observable} from "mobx";

class UIStore {

    rootStore;
    @observable keyboardKeys = {};

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.updateSpecialKeys(new KeyboardEvent("init"))
    }

    updateSpecialKeys = keyboardEvent => {
        for(var key in keyboardEvent){
            this.keyboardKeys[key] = keyboardEvent[key];
        }
    };

}

export default UIStore;