import React from 'react';
import './style.scss';
import Module from "../module/module";
import {makeDragAndDropable} from "../../utils/draganddrop/draganddrop";
import classNames from "classnames";
import DropTarget from "../../utils/draganddrop/droptarget";
import {observer} from "mobx-react";

const DraggableModule = makeDragAndDropable(Module);

@observer
class Period extends React.Component {

    static defaultProps = {
        editMode : false,
        onClick: () => {}
    };

    state = {
        dragOver: false
    };

    contentSpacing = {x: 20, y: 0};

    renderModule = (module, i) => {
        return (
            <DraggableModule
                key={i}
                dragCopy={false}
                isPositionRelative={true}
                editMode={this.props.editMode}
                dragData={module}
                draggable={true}
                onDelete={() => this.props.onModuleDelete(module)}
                onOpenModule={() => this.props.onOpenModule(module)}
                x={i * this.contentSpacing.x}
                y={i * this.contentSpacing.y}
                {...module}
            />
        );
    };

    setDragOver = dragOver => {
        this.setState({dragOver});
    };

    onDrop = dragData => {
        this.setDragOver(false);
        this.props.onDrop(this.props.id, dragData);
    };

    renderAddOptionIfAvailable(){
        return this.props.editMode && (
            <div
                className="add"
                onClick={() => this.props.onClick()}
            >+</div>
        );
    }

    render() {
        const className = classNames({
            period: true,
            ["period--dragOver"]: this.state.dragOver
        });

        return (
            <DropTarget
                enabled={true}
                onHit={this.onDrop}
                onDragEnter={() => this.setDragOver(true)}
                onDragLeave={() => this.setDragOver(false)}
            >
                <div className={className}>
                    {this.renderAddOptionIfAvailable()}
                    {this.props.modules.map(this.renderModule)}
                </div>
            </DropTarget>
        );
    }

}

export default Period;
