/**
 * Store for accessing all other stores
 */
import LayoutStore from "./layoutstore";
import UIStore from "./uistore";
import DataService from "../services/dataservice";
import SimpleStore from "./simplestore";

class RootStore {

    layoutStore;
    moduleStore;
    uiStore;

    setupStores() {
        this.layoutStore = new LayoutStore(this, new DataService('/layouts'));
        this.moduleStore = new SimpleStore(this, new DataService('/modules'));

        this.layoutStore.loadLayouts();
        this.moduleStore.load();

        this.uiStore = new UIStore(this);
    }

}

export default RootStore;