import DelegateDictionary from './delegatedictionary';

class KeyboardHandler {

    constructor(data = {}) {
        this.init();
        this.keyDownHandlers = new DelegateDictionary(data.keyDown);
        this.keyUpHandlers = new DelegateDictionary(data.keyUp);
    }

    init() {
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('keyup', this.handleKeyUp);
    }

    clear() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    handleKeyDown = e => {
        const key = this.getKey(e);
        const isInvoked = this.keyDownHandlers.invoke(key, e);
        if ( isInvoked ){
            e.preventDefault();
        }
    };

    getKey(e) {
        const specialKeys = [ 'metaKey', 'altKey', 'ctrlKey', 'shiftKey' ];
        let key = '';

        let currentKey = e.key || e.keyIdentifier;

        specialKeys.forEach(specialKey => {
            if ( e[ specialKey ] && specialKey.indexOf(currentKey.toLowerCase()) == -1 ){
                key += `${specialKey}_`;
            }
        });
        return `${key}${e.key}`;
    }

    handleKeyUp = e => {
        const isInvoked = this.keyUpHandlers.invoke(e.key, e);
        if ( isInvoked ){
            e.preventDefault();
        }
    };

}

export default KeyboardHandler;