import React from 'react';
import './style.scss';
import Layout from "../../components/layout/layout";
import Storecontext from "../../contexts/storecontext";
import {observer} from "mobx-react";
import ShortCuts from "../../utils/shortcuts";
import Search from "../../components/search/search";


const periods = [
    {id: 1, year: 1, period: 1},
    {id: 2, year: 1, period: 2},
    {id: 3, year: 1, period: 3},
    {id: 4, year: 1, period: 4},
    {id: 5, year: 2, period: 1},
    {id: 6, year: 2, period: 2},
    {id: 7, year: 2, period: 3},
    {id: 8, year: 2, period: 4},
    {id: 9, year: 3, period: 1},
    {id: 10, year: 3, period: 2},
    {id: 11, year: 3, period: 3},
    {id: 12, year: 3, period: 4},
    {id: 13, year: 4, period: 1},
    {id: 14, year: 4, period: 2},
    {id: 15, year: 4, period: 3},
    {id: 16, year: 4, period: 4},
];

@observer
class Modules extends React.Component {

    static contextType = Storecontext;

    state = {
        searchOpen : false
    };

    constructor(props){
        super(props);
        setTimeout(() => this.forceUpdate(), 3000);
    }

    onDrop = (layoutId, trackId, periodId, droppedModule) => {
        const {uiStore, layoutStore} = this.context;
        const {metaKey, shiftKey} = uiStore.keyboardKeys;
        if(shiftKey){
            return layoutStore.cloneModuleToLayout(droppedModule, layoutId, trackId, periodId);
        }
        layoutStore.updateLayoutModule(droppedModule, layoutId, trackId, periodId);
    };

    openSearch = () => {
        this.setState({ searchOpen : true });
    };

    closeSearch = () => {
        this.setState({ searchOpen : false });
    };

    onPeriodSelected = targetPeriod => {
        this.selectedPeriod = targetPeriod;
        this.openSearch();
    };

    onModuleSelected = module => {
        const {layoutStore} = this.context;
        const {selectedPeriod} = this;
        const track = selectedPeriod.track;
        const layout = track.layout;
        this.closeSearch();
        layoutStore.addModuleToLayout(module, layout.id, track.id, selectedPeriod.id);
    };

    onOpenModule = targetModule => {
        console.log(targetModule);
    };

    onModuleDelete = targetModule => {
        const {layoutStore} = this.context;
        layoutStore.removeModuleFromLayout(targetModule);
    };

    renderLayout = (layout, i) => {
        return (
            <Layout
                key={i}
                onDrop={this.onDrop}
                periods={periods}
                onModuleDelete={this.onModuleDelete}
                onOpenModule={this.onOpenModule}
                onPeriodClick={this.onPeriodSelected}
                editMode={this.context.uiStore.keyboardKeys.shiftKey}
                {...layout}
            />
        );
    };

    render() {
        const {layoutStore, uiStore, moduleStore} = this.context;

        return (
            <div className="modules">
                <ShortCuts
                    keyUp={{
                        'always': uiStore.updateSpecialKeys
                    }}
                    keyDown={{'always': uiStore.updateSpecialKeys}}
                />
                <div className="layoutsContainer">
                    {layoutStore.layouts.map(this.renderLayout)}
                </div>
                <Search
                    placeHolder="Zoek module om toe te voegen"
                    data={moduleStore.data}
                    onBlur={this.closeSearch}
                    isVisible={this.state.searchOpen}
                    filter={(d, query) => d.title.indexOf(query) > -1}
                    onSelect={this.onModuleSelected}
                />
            </div>
        );
    }

}

export default Modules;
