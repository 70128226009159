import { action } from 'mobx';

class Model {

    previousState = {};

    @action
    update(update) {
        if(!update){
            return;
        }
        this.storePreviousState();
        Object.keys(update).forEach(key => {
            if ( update[ key ] === undefined ){
                return;
            }
            this[ key ] = update[ key ];
        });
    }

    storePreviousState() {
        Object.keys(this).forEach(
            key => {
                if ( key === "previousState" ){
                    return;
                }
                this.previousState[ key ] = this[ key ];
            });
    }

    @action
    rollBack() {
        Object.keys(this.previousState).forEach(
            key => this[ key ] = this.previousState[ key ]);
    }

}

export default Model;
