/**
 * Simple store for container default data
 */
import {observable} from "mobx";

class SimpleStore {

    rootStore;
    service;
    ModelClass;
    @observable data = [];

    constructor(rootStore, service, ModelClass) {
        this.service = service;
        this.ModelClass = ModelClass;
        this.rootStore = rootStore;
    }

    /***
     * Load all the models
     */
    load() {
        this.service.load()
            .then(this.setData);
    }

    setData = models => {
        models.forEach(model => this.addModel(model));
    };

    /***
     * Add a model instance to the store
     * @param id
     * @param name
     * @param colour
     */
    addModel(model) {
        // todo: model class maken
        //const { ModelClass } = this;
        //const newModel = new ModelClass(model);
        this.data.push(model);
    }


}

export default SimpleStore;