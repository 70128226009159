import React from 'react';
import ShortcutHandler from './shortcuthandler';

class ShortCuts extends React.Component {

    componentWillMount() {
        const { keyUp, keyDown } = this.props;
        this.shortCutHandlers = new ShortcutHandler({
            keyUp,
            keyDown
        });
    }

    componentWillUnmount() {
        this.shortCutHandlers.clear();
    }

    render() {
        return null;
    }
}

export default ShortCuts;

ShortCuts.defaultProps = {
    keyUp : {},
    keyDown : {}
};