import {action, observable, computed} from "mobx";
import Track from "./track";
import Model from "./model";

class Layout extends Model {

    id;
    @observable title;
    @observable comments;
    @observable tracks = [];

    constructor({id, title, comments}){
        super();
        this.id = id;
        this.title = title;
        this.comments = comments;

    }

    set trackCount(trackCount){
        this.tracks.clear();
        for (let i = 0; i < trackCount; i++) {
            this.addTrack();
        }
    }

    @computed
    get trackCount(){
        return this.tracks.length;
    }

    @action
    addTrack(){
        const track = new Track(16);
        track.layout = this;
        this.tracks.push(track);
        track.id = this.tracks.length;
    }

    @action
    addModule(trackId, periodId, module, layoutModuleId){
        const targetTrack = this.tracks.find(track => track.id === trackId);
        return targetTrack.addModule(periodId, module, layoutModuleId);
    }

}

export default Layout;