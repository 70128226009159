import React from 'react';
import DropTarget from './droptarget';
import styles from './dragdrop.module.scss';
import { getEvents } from "../events.utils";
import { usesLeftButtonOrTouch } from "../mouse.utils";

let events = getEvents();

const getClientCoordinates =
    e => (e.touches && e.touches[ 0 ]) ? e.touches[ 0 ] : e;


/**
 * High Order Component for making components drag and dropable
 * @param ComposedComponent
 * @returns {function(*): *}
 */
const makeDragAndDropable = ComposedComponent => class extends React.Component {

    static defaultProps = {
        onDragStart : () => {
        },
        onDrop : () => {
        },
        onClick : () => {
        },
        isPositionRelative : false,
        dragCopy : false,
        targetKey : 'on',
        draggingClassname : 'dragging',
        draggable : true
    };

    dragElem = null;
    dragElemContainer = null;
    container = null;
    currentTarget = null;
    prevTarget = null;

    constructor(props) {
        super(props);

        this.state = {
            x : 0,
            y : 0,
            isDragging : false,
            movedMouse : false
        };
        this.resetSize();
    }

    buildCustomEvent = (eventName, x, y) => {
        let e;
        if ( typeof window.CustomEvent !== 'function' ){
            e = document.createEvent('CustomEvent');
            e.initCustomEvent(eventName, true, true, {});
        }
        else{
            e = new CustomEvent(eventName,
                                { bubbles : true, cancelable : true });
        }
        const bounds = this.currentTarget.getBoundingClientRect();
        const dragElemBounds = this.dragElem.getBoundingClientRect();

        Object.assign(e, {
            dragData : this.props.dragData,
            dragElem : this.dragElem,
            sourceElem : this.container,
            clientX : x,
            clientY : y,
            mousePosition : {
                x,
                y,
                localX : x - bounds.left + this.currentTarget.scrollLeft,
                localY : y - bounds.top + this.currentTarget.scrollTop
            },
            elementPosition : {
                x : dragElemBounds.left,
                y : dragElemBounds.top,
                localX : dragElemBounds.left - bounds.left + this.currentTarget.scrollLeft,
                localY : dragElemBounds.top - bounds.top + this.currentTarget.scrollTop,
            }
        });
        e.mousePosition.relativeX = e.mousePosition.localX / this.currentTarget.clientWidth * 100;
        e.mousePosition.relativeY = e.mousePosition.localY / this.currentTarget.clientHeight * 100;
        e.elementPosition.relativeX = e.elementPosition.localX / this.currentTarget.clientWidth * 100;
        e.elementPosition.relativeY = e.elementPosition.localY / this.currentTarget.clientHeight * 100;
        return e;
    };

    setCurrentTarget = (x, y) => {
        if ( !this.dragElemContainer ){
            return;
        }
        const z = this.dragElemContainer.style.zIndex;
        this.dragElemContainer.style.zIndex = -1;
        let target = document.elementFromPoint(x, y) || document.body;
        target = target.dataset.isDropTarget ? target : target.parentElement;
        this.dragElemContainer.style.zIndex = z;
        this.currentTarget = this.dragElemContainer.contains(target) ? document.body : target;
    };

    generateEnterLeaveDragEvents = (x, y) => {
        const prefix = this.props.targetKey;
        this.setCurrentTarget(x, y);
        if ( this.currentTarget !== this.prevTarget ){
            if ( this.prevTarget ){
                this.prevTarget.dispatchEvent(this.buildCustomEvent(`${prefix}DragLeave`,
                                                                    x,
                                                                    y));
            }
            if ( this.currentTarget ){
                this.currentTarget.dispatchEvent(this.buildCustomEvent(`${prefix}DragEnter`,
                                                                       x,
                                                                       y));
            }
        }
        else{
            if ( this.currentTarget ){
                this.currentTarget.dispatchEvent(this.buildCustomEvent(`${prefix}Drag`,
                                                                       x,
                                                                       y));
            }
        }
        this.prevTarget = this.currentTarget;
    };

    generateDropEvent = (x, y) => {
        this.setCurrentTarget(x, y);
        if ( !this.currentTarget ){
            return;
        }
        const customEvent = this.buildCustomEvent(`${this.props.targetKey}Drop`,
                                                  x,
                                                  y);
        this.currentTarget.dispatchEvent(customEvent);
    };

    resetSize() {
        this.parentElementRect = {
            width : 0,
            height : 0
        };
        this.composedComponentRect = this.parentElementRect;
    }

    componentWillUnmount() {
        document.removeEventListener(events.up,
                                     this.stopDrag,
                                     { capture : false, passive : false });

        if ( this.state.isDragging ){
            //this.stopDrag();
            this.isUnmounted = true;
        }
    }

    startDrag(startX, startY) {
        this.setState({ isDragging : true });
        this.lastMouseX = startX;
        this.lastMouseY = startY;

        this.dragTarget.addEventListener(events.up,
                                         this.stopDrag,
                                         { capture : false, passive : false });
        this.dragTarget.addEventListener(events.move,
                                         this.handleDrag,
                                         { capture : false, passive : false });
        this.props.onDragStart(this.props.dragData);
        document.body.classList.add(`${this.props.draggingClassname}-${this.props.targetKey}`);
    }

    onMouseDown = e => {
        if ( !usesLeftButtonOrTouch(e) || !this.props.draggable ){
            if(!this.props.draggable){
                this.props.onClick(this.props);
            }
            return;
        }
        const isTouch = e.type === 'touchstart';
        events = getEvents(isTouch);
        this.dragTarget = isTouch ? e.target : document.body;
        const { clientX, clientY } = getClientCoordinates(e);
        e.preventDefault();
        e.stopPropagation();
        this.startDrag(clientX, clientY);
    };

    stopDrag = e => {
        document.body.classList.remove(`${this.props.draggingClassname}-${this.props.targetKey}`);
        const { clientX = this.lastMouseX, clientY = this.lastMouseY } = getClientCoordinates(
            e);

        this.dragTarget.removeEventListener(events.up,
                                            this.stopDrag,
                                            {
                                                capture : false,
                                                passive : false
                                            });
        this.dragTarget.removeEventListener(events.move,
                                            this.handleDrag,
                                            {
                                                capture : false,
                                                passive : false
                                            });
        if ( !this.state.movedMouse ){
            this.props.onClick(this.props);
        } else {
            this.generateDropEvent(clientX, clientY);
        }
        this.props.onDrop();
        if ( this.isUnmounted ){
            return;
        }
        this.setState({ isDragging : false, movedMouse : false, x : 0, y : 0 });
        this.resetSize();
    };

    handleDrag = e => {
        const { clientX, clientY } = getClientCoordinates(e);
        this.drag(clientX, clientY);
        e.preventDefault();
        e.stopPropagation();
    };

    setContainer = container => {
        this.container = container;
        this.updateContainerSize();
    };

    setDragElemContainer = dragElemContainer => {
        this.dragElemContainer = dragElemContainer;
        if ( !this.dragElemContainer ){
            return;
        }
        this.dragElem = this.dragElemContainer.querySelector('div');
    };

    updateContainerSize = () => {
        if ( !this.container ){
            return;
        }
        this.composedComponentElement = this.container.querySelector('div');
        this.parentElementRect = this.container.parentElement.getBoundingClientRect();
        this.composedComponentRect = this.composedComponentElement.getBoundingClientRect();
    };

    drag(mouseX, mouseY) {
        this.generateEnterLeaveDragEvents(mouseX, mouseY);
        let offsetX = mouseX - this.lastMouseX;
        let offsetY = mouseY - this.lastMouseY;
        let x = this.state.x + offsetX;
        let y = this.state.y + offsetY;

        this.setState({ movedMouse : true, x, y });

        this.lastMouseX = mouseX;
        this.lastMouseY = mouseY;

    }

    renderCloneWhenVisible() {
        const rect = (this.props.isPositionRelative) ? this.composedComponentRect : this.parentElementRect;

        const style = {
            transform : `translate(${this.state.x}px,${this.state.y}px)`,
            position : 'fixed',
            pointerEvents : 'none',
            width : this.parentElementRect.width,
            height : this.parentElementRect.height,
            left : rect.left,
            top : rect.top,
            zIndex : 10002
        };

        return rect.width > 0 && (
            <span
                style={style}
                className={styles.copy}
                ref={this.setDragElemContainer}
            >
                <ComposedComponent
                    {...this.props}
                    isDragged={this.state.movedMouse}
                />
            </span>
        );
    }

    renderComponentWhenClone() {
        return (this.props.dragCopy || this.composedComponentRect.width === 0) && (
            <ComposedComponent
                {...this.props}
                isDragged={this.state.movedMouse}
            />
        );
    }

    render() {
        if ( !this.state.isDragging || !this.props.draggable ){
            return (
                <ComposedComponent
                    {...this.props}
                    onMouseDown={this.onMouseDown}
                />
            );
        }

        return (
            <span ref={this.setContainer}>
                {this.renderCloneWhenVisible()}
                {this.renderComponentWhenClone()}
            </span>
        );

    }


};


/**
 * High Order Component for making components targets for dragable components
 * @param ComposedComponent
 * @returns {function(*): *}
 */
const makeDropTarget = ComposedComponent => props => (
    <DropTarget
        {...props}
    >
        <ComposedComponent {...props} />
    </DropTarget>
);

export { makeDragAndDropable, makeDropTarget };
