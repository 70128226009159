import React from 'react';
import './style.scss';
import ReactWordcloud from 'react-wordcloud';
import autoScaler from "../../utils/autoscaler";

const ScalingWordCloud = autoScaler(ReactWordcloud);

class WordCloud extends React.Component {

    static defaultProps = {
        minimumCount: 0,
        currentCategory: -1,
        words: [],
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextProps.minimumCount !== this.props.minimumCount || nextProps.words !== this.props.words || nextProps.currentCategory !== this.props.currentCategory;
    }

    render() {
        const {words, minimumCount, currentCategory} = this.props;
        const filteredWords = words.filter(word => word.value >= minimumCount && (currentCategory == -1 || word.categoryId == currentCategory));

        return (
            <div className="wordCloud">
                <div style={{width: '100%', height: '100%', position: 'absolute'}}>
                    <ScalingWordCloud
                        words={filteredWords}
                        size={[1100, 1000]}
                        callbacks={this.props.callbacks}
                        options={{
                            colors: [
                                '#e30086',
                                'white',
                            ],
                            enableTooltip: true,
                            deterministic: true,
                            fontFamily: 'impact',
                            fontSizes: [5, 70],
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            padding: 1,
                            rotations: 1,
                            rotationAngles: [0, 90],
                            scale: 'log',
                            spiral: 'archimedean',
                            transitionDuration: 1000,
                        }}
                    />
                </div>
            </div>
        );
    }

}

export default WordCloud;
