import {action, observable} from "mobx";
import Layout from "../models/layout";
import {importLayout} from "./helpers/layoutimporter";

/**
 * Store for storing the layouts
 */
class LayoutStore {

    rootStore;
    layoutService;
    @observable layouts = [];

    constructor(rootStore, layoutService) {
        this.rootStore = rootStore;
        this.layoutService = layoutService;
    }

    loadLayouts = () => {
        this.layoutService.load()
            .then(layouts => {
                layouts.forEach(this.addLayout);
            });
    };

    @action
    loadLayout = layoutId => {
        this.layoutService.get(layoutId)
            .then(layout => {
                const targetLayout = this.layouts.find(currentLayout => currentLayout.id === layoutId) || new Layout(layout.id, layout.title, layout.comments);
                importLayout(layout, targetLayout);
            });
    };

    @action
    addLayout = layoutData => {
        const layout = new Layout(layoutData);
        this.loadLayout(layoutData.id);
        this.layouts.push(layout);
    };

    getLayout = layoutId => {
        return this.layouts.find(layout => layout.id === layoutId);
    };

    addModule = (targetModule, layoutId, trackId, periodId) => {
        const targetLayout = this.getLayout(layoutId);
        return targetLayout.addModule(trackId, periodId, targetModule);
    };

    moveModule = (targetModule, layoutId, trackId, periodId) => {
        targetModule.decouple();
        return this.addModule(targetModule, layoutId, trackId, periodId);
    };

    updateLayoutModule = (targetModule, layoutId, trackId, periodId) => {
        targetModule = this.moveModule(targetModule, layoutId, trackId, periodId);
        const layoutModuleId = targetModule.layoutModuleId;
        this.layoutService.fetch(`/layout-modules/${layoutModuleId}`, {
            layout_id: layoutId,
            track_number: trackId,
            period_id: periodId
        }, {method: 'PATCH'})
            .then(update => this.moveModule(targetModule, update.layout_id, update.track_number, update.period_id));
    };

    cloneModuleToLayout = (targetModule, layoutId, trackId, periodId) => {
        targetModule = this.addModule(targetModule, layoutId, trackId, periodId);
        this.layoutService.fetch(`/layout-modules`, {
            layout_id: layoutId,
            track_number: trackId,
            period_id: periodId,
            module_id: targetModule.id
        }, {method: 'POST'})
            .then(update => this.moveModule(targetModule, update.layout_id, update.track_number, update.period_id));
    };

    addModuleToLayout = (module, layoutId, trackId, periodId) => {
        this.layoutService.fetch(`/layout-modules`, {
            layout_id: layoutId,
            track_number: trackId,
            period_id: periodId,
            module_id: module.id
        }, {method: 'POST'})
            .then(update => {
                module.layoutModuleId = update.id;
                this.addModule(module, layoutId, trackId, periodId)
            });
    };

    removeModuleFromLayout = targetModule => {
        const layoutModuleId = targetModule.layoutModuleId;
        this.layoutService.fetch(`/layout-modules/${layoutModuleId}`, undefined, {method: 'DELETE'}, undefined, false)
            .then(update => targetModule.decouple());
    };

}

export default LayoutStore;
