import {getQueryString} from "../utils/url.utils";

const accessToken = "accessToken";

export default class Service {

    constructor(domain = /*'http://localhost:8000/api'*/'https://softwaredeveloper.amsterdam/backend/api', token = "cx9nkbMmdetWkYil1jxfuwCRmBFsiad7EB0DHLV3ualxxpWYFzSAMnUxlt20bbpk1rfrAojUA2zl8YvA") {
        this.domain = domain;
        this.setToken(accessToken, token);
    }

    setToken(tokenId, token) {
        localStorage.setItem(tokenId, token);
    }

    getToken(tokenId) {
        return localStorage.getItem(tokenId);
    }

    logout() {
        localStorage.removeItem(accessToken);
    }

    fetch(endPoint, body, options, query, parseJSON = true) {

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };

        const currentOptions = Object.assign({}, options, {
            body: JSON.stringify(body)
        });

        const queryString = getQueryString(query);

        if (this.isLoggedIn) {
            headers.Authorization = `Bearer ${this.getToken(accessToken)}`;
        }

        return fetch(`${this.domain}${endPoint}${queryString}`, {
            headers,
            ...currentOptions
        })
            .then(this._checkStatus)
            .then(response => parseJSON && response.json());
    }

    /***
     * Get JSON
     * @param url
     * @returns {Promise<any>}
     */
    get(endPoint, query) {
        return this.fetch(endPoint);
    };

    /***
     * Post a JSON object to a URL
     * @param url
     * @param body
     * @returns {Promise<Response>}
     */
    post(endPoint, body, query) {
        return this.fetch(endPoint, body, {method : 'POST'}, query);
    };

    delete(endPoint, query) {
        return this.fetch(endPoint, undefined, { method : 'DELETE' }, query);
    };

    /***
     * Patch an object to a URL
     * @param url
     * @param body
     * @param query
     * @returns {Promise<Response>}
     */
    patch(endPoint, body, query) {
        return this.fetch(endPoint, body, {method : 'PATCH'}, query);
    };

    _checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response;
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    }

    get isLoggedIn() {
        const token = this.getToken(accessToken);
        return !!token; // todo: eventueel nog toevoegen dat tokens vervallen
    }
}