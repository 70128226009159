import React from 'react';
import classNames from 'classnames';

const noCallback = () => {
};

class SearchResult extends React.Component {

    render() {
        const { id, title, content, isSelected, onClick = noCallback } = this.props;

        const className = classNames({
                                         'search__result' : true,
                                         'search__result--selected' : isSelected
                                     });

        return (
            <div
                className={className}
                onMouseDown={() => onClick(id)}
            >
                <div className="search__title">{title}</div>
                <div>{content}</div>
            </div>
        );
    }
}

export default SearchResult;