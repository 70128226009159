import React from 'react';
import SearchResult from './searchresult';
import classNames from 'classnames';
import Dictionary from '../../utils/dictionary';
import './style.scss';

const noCallBack = () => {
};

class Search extends React.Component {

    state = {
        searchResult : [],
        query : ''
    };

    constructor(props) {
        super(props);

        this.keys = new Dictionary({
            'Escape' : props.onBlur,
            'ArrowDown' : this.selectNext,
            'ArrowUp' : this.selectPrevious,
            'Enter' : this.onSelect,
            'default' : noCallBack
        });
    }

    componentDidUpdate(prevProps) {
        if ( this.props.isVisible && !prevProps.isVisible ){
            this.searchInput.focus();
        }
    }

    handleKeyDown = e => {
        this.keys.get(e.key)();
    };

    searchTickets = query => {
        const {data} = this.props;
        const searchResult = data.filter(d => this.props.filter(d, query));
        this.setState({ searchResult : searchResult });
    };

    selectNext = () => {
        this.selectIndex(this.state.selectedIndex + 1);
    };

    selectPrevious = () => {
        this.selectIndex(this.state.selectedIndex - 1);
    };

    selectIndex = selectedIndex => {
        if ( selectedIndex < 0 || selectedIndex > this.state.searchResult.length - 1 ){
            return;
        }
        this.setState({ selectedIndex });
    };

    onSearch = e => {
        const query = e.target.value;
        this.setState({ query : query, selectedIndex : -1 });
        this.searchTickets(query);
    };

    onSelect = () => {
        const result = this.state.searchResult[ this.state.selectedIndex ];
        this.props.onSelect(result);
    };

    renderSearchResult = (item, i) =>
        (
            <SearchResult
                key={i}
                id={item.id}
                isSelected={i == this.state.selectedIndex}
                title={item.title}
                content={item.content}
                onClick={() => this.props.onSelect(item)}
            />
        );

    render() {
        const { isVisible, onBlur = noCallBack } = this.props;
        const {searchResult} = this.state;
        const className = classNames({
                                         'search' : true,
                                         'search--visible' : isVisible
                                     });

        return (
            <div
                className={className}
                onKeyDown={this.handleKeyDown}
                onBlur={onBlur}
            >
                <input
                    type="text"
                    className="search__input"
                    onChange={this.onSearch}
                    placeholder={this.props.placeHolder}
                    ref={(input) => { this.searchInput = input; }}
                />
                {searchResult.map(this.renderSearchResult)}
            </div>
        );
    }
}

export default Search;