import {observable, action} from "mobx";
import Period from "./period";

class Track {

    @observable periods = [];
    layout;
    id;
    trackCount;

    constructor(periodCount){
        for (let i = 0; i < periodCount; i++) {
            this.addPeriod();
        }
    }

    addPeriod(){
        const newPeriod = new Period();
        this.periods.push(newPeriod);
        newPeriod.id = this.periods.length;
        newPeriod.track = this;
    }

    @action
    addModule(periodId, module, layoutModuleId){
        const targetPeriod = this.periods.find(p => p.id === periodId);
        return targetPeriod.addModule(module, layoutModuleId);
    }

}

export default Track;