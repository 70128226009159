import React from 'react';
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import StudentProfile from "./routes/studentprofile/studentprofile";
import Modules from "./routes/modules/modules";
import RootStore from "./stores/rootstore";
import StoreContext from "./contexts/storecontext";

class App extends React.Component {

    rootStore;

    constructor(props) {
        super(props);
        this.rootStore = new RootStore();
        this.rootStore.setupStores();
    }

    render() {
        return (
            <Router>
                <StoreContext.Provider value={this.rootStore}>
                    <div>
                        <nav className="navigation">
                            <ul>
                                <li>
                                    <Link to="/studentprofile">Studentprofiel</Link>
                                </li>
                                <li>
                                    <Link to="/modules">Modules</Link>
                                </li>
                            </ul>
                        </nav>

                        <Switch>
                            <Route path="/modules">
                                <Modules/>
                            </Route>
                            <Route path="/studentprofile">
                                <StudentProfile/>
                            </Route>
                        </Switch>
                    </div>
                </StoreContext.Provider>
            </Router>
        );
    }

}

export default App;
