import Service from './service';

class DataService extends Service {

    rootEndPoint = '';

    constructor(rootEndPoint, domain, token){
        super(domain, token);
        this.rootEndPoint = rootEndPoint;
    }

    load = query => {
        return super.get(this.rootEndPoint, query);
    };

    delete(id, query) {
        return super.delete(`${this.rootEndPoint}/${id}`, query);
    };

    patch(id, body, query) {
        return super.patch(`${this.rootEndPoint}/${id}`, body, query);
    };

    post(body, query) {
        return super.post(`${this.rootEndPoint}`, body, query);
    };

    get(id, query) {
        return super.get(`${this.rootEndPoint}/${id}`, query);
    };

}

export default DataService;