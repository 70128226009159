import React from 'react';
import './style.scss';
import WordCloud from "../../components/wordcloud/wordcloud";
import w from '../../words';
import DropDown from "../../utils/dropdown/dropdown";
import classNames from "classnames";
import ClickOutside from "../../utils/clickoutside";

const words = w.map(word => ({text: word.label, value: word.count, ...word}));

class StudentProfile extends React.Component {

    state = {
        minimumCount: 0,
        currentCategory : -1,
        selectedWord: undefined
    };

    categories = [{id: 1, type: 'kennis', description: 'heeft kennis over %word%'}, {
        id: 2,
        type: 'vaardigheid',
        description: 'kan %word% maken '
    }, {id: 3, type: 'houding', description: 'is %word%'}];

    getCategory(id) {
        return this.categories.find(category => category.id === id);
    }

    renderMinimumCountOption = option => {
        return (
            <option key={option}>{option}</option>
        )
    }

    setMinimumCount = (id, minimumCount) => {
        this.setState({minimumCount});
    };

    setCategory = (id, currentCategory) => {
        this.setState({currentCategory});
    };

    selectWord = (word = undefined) => {
        if(word == this.state.selectedWord){
            return;
        }
        this.setState({selectedWord: word});
    };

    onWordSelected = word => {
        this.selectWord(word.text);
    };

    render() {
        const {selectedWord, currentCategory} = this.state;
        const sideMenuClassname = classNames({
            sideMenu: true,
            ["sideMenu--open"]: selectedWord

        });

        const categories = this.categories.map(category => ({label: category.type, value: category.id}));
        categories.splice(0, 0, {label: 'alle categorien', value: -1});

        return (
            <div className="studentProfile">
                <WordCloud
                    words={words}
                    minimumCount={this.state.minimumCount}
                    currentCategory={this.state.currentCategory}
                    callbacks={{
                        getWordTooltip: ({text, value, description}) => {
                            return `Een afgestudeerd SD-er ${description} (komt ${value}x voor)`
                        },
                        onWordClick: this.onWordSelected
                    }}
                />
                <ClickOutside
                    className={sideMenuClassname}
                    onClickOutside={this.selectWord}
                >
                    <h1>{selectedWord}</h1>
                    <h3>Meer informatie over {selectedWord}...</h3>
                </ClickOutside>
                <div className="filterMenu">
                    <DropDown
                        id="minimumCount"
                        suffix="x of meer voorkomend"
                        onChange={this.setMinimumCount}
                        value={this.state.minimumCount}
                        options={[
                            {label: '1', value: 1},
                            {label: '2', value: 2},
                            {label: '3', value: 3},
                            {label: '5', value: 5},
                            {label: '8', value: 8},
                            {label: '13', value: 13},
                            {label: '20', value: 20},
                        ]}/>
                    <DropDown
                        id="category"
                        onChange={this.setCategory}
                        value={this.state.currentCategory}
                        options={categories}/>
                </div>

            </div>
        );
    }

}

export default StudentProfile;
