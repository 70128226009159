const encodeDate = value => value instanceof Date ? value.toISOString().split(
    'T')[0] : value;
const encode = value => {
    let encodedValue = encodeDate(value);
    return encodeURIComponent(encodedValue);
};

const getQueryString = target => {
    let queryString = [];
    let value;
    for (let key in target) {
        value = target[key];
        if (value === undefined) {
            continue;
        }
        if (value instanceof Array) {
            value = value.forEach(v => queryString.push(`${key}=${encode(v)}`));
            continue;
        } else {
            value = encode(value);
        }
        queryString.push(`${key}=${value}`);
    }
    return queryString.length > 0 ? `?${queryString.join('&')}` : '';
};

export {getQueryString, encode, encodeDate}