import React from 'react';
import PropTypes from 'prop-types';

const iddleHandler = () => {
};

class DropTarget extends React.Component {
    constructor(props) {
        super(props);
        this.elem = null;
        this.handleDrop = this.handleDrop.bind(this);
    }

    componentDidMount() {
        if ( !this.props.enabled ){
            return;
        }
        this.elem.dataset.isDropTarget = true;
        this.elem.addEventListener(`${this.props.targetKey}DragEnter`, (e) => {
            this.props.onDragEnter(e);
        }, false);
        this.elem.addEventListener(`${this.props.targetKey}Drag`, (e) => {
            this.props.onDrag(e);
        }, false);
        this.elem.addEventListener(`${this.props.targetKey}DragLeave`, (e) => {
            this.props.onDragLeave(e);
        }, false);
        this.elem.addEventListener(`${this.props.targetKey}Drop`, (e) => {
            this.handleDrop(e);
        }, false);
    }

    createEvent(eventName, eventData) {
        // utility to create an event
        let e;
        if ( typeof window.CustomEvent !== 'function' ){
            // we are in IE 11 and must use old-style method of creating event
            e = document.createEvent('CustomEvent');
            e.initCustomEvent(eventName, true, true, {});
        }
        else{
            e = new CustomEvent(eventName,
                                { bubbles : true, cancelable : true });
        }
        Object.assign(e, eventData);
        return e;
    }

    handleDrop(e) {
        // tell the drop source about the drop, then do the callback
        const evt = this.createEvent(`${this.props.targetKey}Dropped`,
                                     {
                                         dropElem : this.elem,
                                         dropData : this.props.dropData
                                     });
        e.sourceElem.dispatchEvent(evt);
        this.props.onHit(e);
    }

    render() {
        return (
            <span
                className={this.props.className}
                style={this.props.style}
                title={this.props.title}
                onMouseDown={this.props.onMouseDown}
                onMouseUp={this.props.onMouseUp}
                onMouseMove={this.props.onMouseMove}
                ref={t => {
                    this.elem = t;
                    this.props.reference(t);
                }}
            >
        {this.props.children}
      </span>
        );
    }
}

DropTarget.propTypes = {
    children : PropTypes.node.isRequired,

    // needs to match the targetKey in the DragDropContainer
    targetKey : PropTypes.string,

    // data that gets sent back to the DragDropContainer and shows up in its
    // onDrop() callback event
    dropData : PropTypes.object,

    // callbacks
    onDragEnter : PropTypes.func,
    onDragLeave : PropTypes.func,
    onHit : PropTypes.func,
};

DropTarget.defaultProps = {
    enabled : true,
    targetKey : 'on',
    reference : () => {
    },
    onDragEnter : iddleHandler,
    onDragLeave : iddleHandler,
    onDrag : iddleHandler,
    onHit : () => iddleHandler,
    dropData : {},
    className : '',
    title : ''
};

export default DropTarget;
