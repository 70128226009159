import {observable, action} from "mobx";

class Module {

    @observable id;
    @observable title;
    period;
    layoutModuleId;

    constructor(id, title){
        this.id = id;
        this.title = title;
    }

    @action
    decouple(){
        if(this.period !== undefined){
            this.period.deleteModule(this);
        }
        this.period = undefined;
    }

}

export default Module;